// External dependencies.
import { path, omit, max, min } from 'ramda'
import { toast } from 'react-toastify'
import i18n from 'i18n'
import { call, select, put, fork } from 'redux-saga/effects'
// Internal dependencies.
import UploadActions from 'modules/upload/actions'
import { token as getToken } from 'modules/users/selectors'
import { stableSort } from 'utils/sort'
import { createJob } from 'modules/jobs/api'
import { createJobRun } from 'modules/jobRuns/api'
import { createDataDirectory } from 'modules/artifacts/api'
import { createPipeline } from 'modules/pipelines/api'
import { createJobIO } from 'modules/jobIOs/api'
import ArtifactsActions from 'modules/artifacts/actions'
import {
  isCamFileType,
  isLdrFileType,
  isTrjFileType,
  isPlpFileType,
  isLogFileType,
  isLasFileType,
  isDataFileType,
  isLasFileName,
  isTrjFileName,
  shouldDisplayTrajectoryImportForm,
} from 'modules/upload/file-types'
// import API from '../projects/api'
import history from 'browserHistory'
import { routeProjectPipelinesJobRun } from 'utils/routing'
import { getBackendTransformedValues } from 'utils/templates'
import {
  prettifyArtifactType,
  isArtifactIntervalValid,
  isArtifactTypeUsingDirectoryApi,
  isCameraArtifactHasLadybugSensor,
} from 'utils/artifacts'
import { concatMap, partition, selectiveReduce } from 'utils/list'
import { keepProps } from 'utils/dict'
import { isFileImage } from 'utils/fileTypes'
import {
  ArtifactTypes,
  isRefStationArtifact,
  isTrajectoryArtifact,
  isLogArtifact,
  isLidarArtifact,
  isCameraArtifact,
  isGCPArtifact,
  isPointcloudArtifact,
  isReconDataArtifact,
  isDocumentationArtifact,
  isGeotiffArtifact,
  convertRawArtifact,
  isPolygonArtifact,
} from 'types/artifacts'
import { JobType } from 'types/jobs'
import { IOType } from 'types/jobIos'
import { createProtoArtifact } from 'utils/protoArtifacts'
import { extractFileProperties } from 'utils/fileProperties'
import { getTrajectoryCRSTemplate, getTrajectoryCRSTemplateFieldNames, TrajectoryTemplateFieldNames } from 'templates/CRS/trajectoryCRSTemplate'
import { RefStationTemplate } from 'templates/refStationTemplate'
import { isNumber } from 'components/CoordinateField/utils'
import { getAuthentificationHeader, resolveTasks } from 'utils/api'
import { ProjectCRSTemplateInfo } from 'templates/CRS/ProjectCRS/ProjectCRSTemplateInfo'
import { GCP_BACKEND_NAME } from 'templates/constants'
import { LasAllSettingsTemplate, LasCRSTemplate, LasSettingsFieldNames, LasVisualizationSettingsFieldsNames } from 'templates/CRS/LasCRSTemplate'
import { UploaderType } from 'types/upload'
import { isDocFileType } from 'modules/upload/file-types/doc'
import { getBaseName } from 'utils/baseName'
import { isGeotiffFileType } from 'modules/upload/file-types/geotiff'
import axios from 'utils/axios'
import { transformLegacyGpsTimeInterval } from 'utils/gpsTime'

// const createSuggestionProtoArtifacts: ProtoArtifactReducer = (state: ProtoArtifactReducerState)
export const createSuggestionProtoArtifacts = state => {
  const { protoArtifacts, files, missionId, plp } = state
  // The cast is required, because the TS typesystem cannot deduce that all files in this array are Plp files.
  const { true: plpFiles, false: nonPlpFiles } = partition(file => isPlpFileType(file.fileType) && file.okay, files)
  const suggestionArtifacts = concatMap(plpFile => plpFile.parseResult.result.protoArtifacts, plpFiles)
  return {
    protoArtifacts: [ ...protoArtifacts, ...suggestionArtifacts.map(art => ({ ...art, missionId })) ],
    files: nonPlpFiles,
    missionId,
    plp,
  }
}

// export function mergeHeuristicsIntoSuggestion(
//   heuristicArtifacts: ProtoArtifact[], suggestionArtifact: ProtoArtifact
// ): {
//   heuristicArtifacts: ProtoArtifact[], suggestionArtifact: ProtoArtifact
// } {
export function mergeHeuristicsIntoSuggestion (heuristicArtifacts, suggestionArtifact) {
  const { files, fileSuggestions } = suggestionArtifact
  const result = selectiveReduce(
    (mergedFiles, heuristicArtifact) => {
      const canBeMerged = heuristicArtifact.files.some(
        analyzedFile => fileSuggestions.some(name => name === analyzedFile.file.name),
      )
      return canBeMerged ? [ ...mergedFiles, ...heuristicArtifact.files ] : undefined
    },
    files,
    heuristicArtifacts,
  )
  return {
    heuristicArtifacts: result.unused,
    suggestionArtifact: { ...suggestionArtifact, files: result.result },
  }
}

/**
 * In theory the importer generates artifacts the same way SE exports it to the Plp.
 * However, in practice the importer is based on heuristics, like file names, and assumptions.
 * Therefore we prefer Plp-generated `ProtoArtifacts` over heuristically generated ones.
 * This function tries to merge regular and suggestion `ProtoArtifacts`.
 * File names are assumed to be unique, therefore each file can only belong to one artifact.
 * If the same file name is found in different artifacts then we move the file to the
 * artifact which has a suggestion for this file.
 */
// export const mergeSuggestionProtoArtifacts: ProtoArtifactReducer = (state: ProtoArtifactReducerState)
export const mergeSuggestionProtoArtifacts = state => {
  const { protoArtifacts, files, missionId } = state
  const { true: suggestionArtifacts, false: heuristicArtifacts } = partition(
    protoArtifact => Boolean(protoArtifact.createdFrom), protoArtifacts,
  )

  // Iterate over all artifacts generated from heuristics.
  const {
    mergedArtifacts: newSuggestionArtifacts,
    remainingArtifacts: remainingHeuristicArtifacts,
  } = suggestionArtifacts.reduce(
    (reduceState, suggestionArtifact) => {
      const {
        heuristicArtifacts: remainingArtifacts,
        suggestionArtifact: mergedArtifact,
      } = mergeHeuristicsIntoSuggestion(reduceState.remainingArtifacts, suggestionArtifact)
      return { mergedArtifacts: [ ...reduceState.mergedArtifacts, mergedArtifact ], remainingArtifacts }
    },
    { mergedArtifacts: [], remainingArtifacts: heuristicArtifacts },
  )

  return {
    protoArtifacts: [ ...newSuggestionArtifacts, ...remainingHeuristicArtifacts ],
    files,
    missionId,
  }
}
const PointcloudMergableFileTypes = ['trj', 'kml', 'shape', 'xml', 'dxf', 'obj']
/**
 * Checks if file can be added to the pointcloud artifact
 * @param {String} fileType
 * @returns {Boolean} can be added to artifact or not
 */
export function canMergeFileIntoPointcloudProtoArtifact (fileType) {
  return PointcloudMergableFileTypes.includes(fileType)
}

// function canMergeFileIntoProtoArtifact(file: AnalyzedFile, protoArtifact: ProtoArtifact)
function canMergeFileIntoProtoArtifact (file, protoArtifact, missionId) {
  const { fileType } = file
  const { artifactType } = protoArtifact
  // Checks whether the file can be merged into the `ProtoArtifact`.
  //  - *.ldr: Can be merged into the `ProtoArtifact` if it is of type `LIDAR_DATA` and file name ends with the same index.
  //  - *.trj: Can be merged into the `ProtoArtifact` if it is of type `TRAJECTORY`.
  //  - *.trj: Can be merged into the `ProtoArtifact` if it is of type `POINTCLOUD` and only if it isn't created by mission.
  //  - *.log: Can be merged into the `ProtoArtifact` if it is of type `LOG`.
  //  - *.las|*.laz: Can be merged into the `ProtoArtifact` if it is of type `LAS`.
  //  - *.data: Can be merged into the `ProtoArtifact` if it is of type `RECON_DATA`.
  //  - *.nav: Can never be merged. Each artifact can contain only one *.nav file.
  //  - *.cam: Can never be merged. Each artifact can contain only one *.cam file.
  if (
    (file.okay) &&
      (
        (isTrjFileType(fileType) && isTrajectoryArtifact(artifactType)) ||
        (isLogFileType(fileType) && isLogArtifact(artifactType)) ||
        ((
          isLasFileType(fileType) ||
          canMergeFileIntoPointcloudProtoArtifact(fileType)
        ) && isPointcloudArtifact(artifactType) && !missionId) ||
        (isDataFileType(fileType) && isReconDataArtifact(artifactType)) ||
        (isDocFileType(fileType) && isDocumentationArtifact(artifactType)) ||
        (isGeotiffFileType(fileType) && isGeotiffArtifact(artifactType))
      )
  ) {
    return true
  } else if (
    isLdrFileType(fileType) && isLidarArtifact(artifactType)
  ) {
    const fileName = file.file.name
    const index = fileName.split(".")[0].split("_").pop()
    // Check if the file name ends with the same index as the index from protoArtifact.properties
    if (index === protoArtifact.properties.index) return true
    return false
  }
  return false
}

export function sortFilesByName (files) {
  return stableSort(
    files,
    (file1, file2) => {
      if (file2.file.name < file1.file.name) {
        return -1
      }
      if (file2.file.name > file1.file.name) {
        return 1
      }
      return 0
    },
  )
}

// function mergeFilesIntoProtoArtifact(protoArtifact: ProtoArtifact, filesToMerge: AnalyzedFile[])
function mergeFilesIntoProtoArtifact (protoArtifact, filesToMerge, missionId) {
  const { files, properties } = protoArtifact
  const result = selectiveReduce(
    (protoArtifactFiles, file) =>
      canMergeFileIntoProtoArtifact(file, protoArtifact, missionId) ? [ ...protoArtifactFiles, file ] : undefined,
    files,
    filesToMerge,
  )
  const { result: mergedFiles } = result
  return {
    protoArtifact: {
      ...protoArtifact,
      files: mergedFiles,
      properties: {
        ...properties,
        fileProperties: {
          ...(properties.fileProperties || {}),
          ...(isPointcloudArtifact(protoArtifact.artifactType) || isPolygonArtifact(protoArtifact.artifactType)
            ? extractFileProperties(mergedFiles.filter(file => !files.find(alreadyAddedFile => alreadyAddedFile.file.name === file.file.name)))
            : extractFileProperties(mergedFiles)
          ),
        },
      },
    },
    files: result.unused,
    missionId,
  }
}

export function mergeFilesIntoArtifact (artifact, filesToMerge) {
  const { properties } = artifact
  return {
    ...artifact,
    properties: {
      ...properties,
      fileProperties: {
        ...properties.fileProperties,
        ...extractFileProperties(filesToMerge),
      },
    },
  }
}

/**
 * Tries to merge all files into all `ProtoArtifact`s.
 * @param files The files which should be merged.
 * @param protoArtifacts The `ProtoArtifact`s which should be appended.
 * @return An object containing `remainingFiles` and `protoArtifacts` with the new `ProtoArtifact`s containing
 *   the new files as well as all files which couldn't be merged into any `ProtoArtifact`s.
 */
export const mergeFilesIntoProtoArtifacts = oldState => oldState.protoArtifacts.reduce(
  // What happens here is that:
  //  - Each `ProtoArtifact` will be matched against...
  //  - ...each remaining file and...
  //  - ...if the file can be inserted into the `ProtoArtifact` it is inserted and...
  //  - ...removed from the list of remaining files.
  (state, oldProtoArtifact) => {
    const { protoArtifact, files, missionId } = mergeFilesIntoProtoArtifact(oldProtoArtifact, state.files, state.missionId)
    return { protoArtifacts: [ ...state.protoArtifacts, protoArtifact ], files, missionId, plp: state.plp }
  },
  { protoArtifacts: [], files: oldState.files, missionId: oldState.missionId, plp: oldState.plp },
)

export const updateArtifactProperties = (plp, artifacts) => {
  if (plp) {
    if (artifacts.some(artifact => isLidarArtifact(artifact.artifactType))) {
      const { sessionsLidars = {} } = plp
      const newArtifacts = artifacts.map(artifact => {
        if (!isLidarArtifact(artifact.artifactType)) return artifact
        const { properties = {} } = artifact
        const { fileProperties, index, fileNames } = properties
        const { sessions } = sessionsLidars[parseInt(index)]
        return {
          ...artifact,
          properties: {
            ...properties,
            fileProperties: fileNames.reduce((allFileProperties, fileName) => {
              const fileNameBaseName = getBaseName(fileName)
              const filePropertiesOfFile = fileProperties[fileName]
              // In case we already have properties for the current file
              // We should just copy them
              if (filePropertiesOfFile) return { ...allFileProperties, [fileName]: filePropertiesOfFile }
              // In other case we find for corresponding session in plp
              // If there is no such - skip
              const correspondingSession = sessions.find(session => getBaseName(session.fileInfo).endsWith(fileNameBaseName))
              return !correspondingSession
                ? allFileProperties
                : { ...allFileProperties, [fileName]: { interval: correspondingSession.interval } }
            }, {}),
          },
        }
      })
      return newArtifacts
    }
  }
  return artifacts
}

// const regenerateArtifactNames: ProtoArtifactReducer = (state: ProtoArtifactReducerState)
export const updateProtoArtifactProperties = state => {
  const { plp, protoArtifacts } = state
  if (plp) {
    if (protoArtifacts.some(protoArtifact => isLidarArtifact(protoArtifact.artifactType))) {
      const parsedPlp = JSON.parse(plp)
      const { sessionsLidars = {} } = parsedPlp
      const newProtoArtifacts = protoArtifacts.map(protoArtifact => {
        if (!isLidarArtifact(protoArtifact.artifactType)) return protoArtifact
        const { properties = {}, files } = protoArtifact
        const { fileProperties, index } = properties
        const { sessions } = sessionsLidars[parseInt(index)]
        return {
          ...protoArtifact,
          properties: {
            ...properties,
            fileProperties: files.reduce((allFileProperties, file) => {
              const fileName = file.file.name
              const fileNameBaseName = getBaseName(fileName)
              const filePropertiesOfFile = fileProperties[fileName]
              // In case we already have properties for the current file
              // We should just copy them
              if (filePropertiesOfFile) return { ...allFileProperties, [fileName]: filePropertiesOfFile }
              // In other case we find for corresponding session in plp
              // If there is no such - skip
              const correspondingSession = sessions.find(session => getBaseName(session.fileInfo).endsWith(fileNameBaseName))
              return !correspondingSession
                ? allFileProperties
                : { ...allFileProperties, [fileName]: { interval: correspondingSession.interval } }
            }, {}),
          },
        }
      })
      return {
        files: state.files,
        protoArtifacts: newProtoArtifacts,
        missionId: state.missionId,
      }
    }
  }
  return state
}

/**
 * Given a file type and an artifact type creates a single `ProtoArtifact`s
 * with the given `ArtifactType` for each file of the supplied `FileType`.
 */
// const produceSingleFileArtifacts:
//     (fileType: FileType, artifactType: ArtifactType) => ProtoArtifactReducer =
//     (fileType: FileType, artifactType: ArtifactType) => (state: ProtoArtifactReducerState)
const produceSingleFileArtifacts = (fileType, artifactType) => state => {
  const { files: analyzedFiles, protoArtifacts, missionId } = state
  const files = analyzedFiles.filter(analyzedFile => analyzedFile.fileType === fileType)
  return {
    files: analyzedFiles.filter(unusedFile => files.indexOf(unusedFile) === -1),
    protoArtifacts: [
      ...protoArtifacts,
      ...files.map(file => createProtoArtifact(artifactType, [file], { missionId })),
    ],
    missionId,
  }
}

/**
 * Given a file type and an artifact type creates one `ProtoArtifact`
 * with the given `ArtifactType` for all files of the supplied `FileType`.
 */
// const produceMultiFileArtifact:
//     (fileType: FileType, artifactType: ArtifactType) => ProtoArtifactReducer =
//     (fileType: FileType, artifactType: ArtifactType) => (state: ProtoArtifactReducerState)
const produceMultiFileArtifact = (fileType, artifactType, nonMissionFileTypes) => state => {
  const { files: analyzedFiles, protoArtifacts, missionId } = state
  const files = analyzedFiles.filter(analyzedFile => analyzedFile.fileType === fileType)
  // We can add additional files based on other fileTypes from 'nonMissionFileTypes'
  // For non-mission artifacts only
  if (nonMissionFileTypes && nonMissionFileTypes.length > 0 && !missionId) {
    analyzedFiles
      .filter(analyzedFile => nonMissionFileTypes.includes(analyzedFile.fileType))
      .forEach(file => {
        files.push(file)
      })
  }
  if (files.length === 0) {
    return state
  }
  const newState = {
    files: analyzedFiles.filter(unusedFile => files.indexOf(unusedFile) === -1),
    protoArtifacts: [
      ...protoArtifacts,
      createProtoArtifact(artifactType, files, { missionId }),
    ],
    missionId,
  }
  return produceMultiFileArtifact(fileType, artifactType)(newState)
}

/**
 * A list of all `ProtoArtifact` producers.
 */
// const protoArtifactProducers: ProtoArtifactReducer[]
const protoArtifactProducers = [
  produceSingleFileArtifacts('nav', ArtifactTypes.NAVIGATION_ROVER),
  produceSingleFileArtifacts('nav_applanix', ArtifactTypes.NAVIGATION_ROVER_APPLANIX),
  produceSingleFileArtifacts('gcp', ArtifactTypes.GROUND_CONTROL_POINTS),
  produceSingleFileArtifacts('cam', ArtifactTypes.CAMERA_DATA),
  produceSingleFileArtifacts('polygon', ArtifactTypes.POLYGON),
  produceMultiFileArtifact('las', ArtifactTypes.POINTCLOUD, PointcloudMergableFileTypes),
  produceMultiFileArtifact('trj', ArtifactTypes.TRAJECTORY),
  produceMultiFileArtifact('ldr', ArtifactTypes.LIDAR_DATA),
  produceMultiFileArtifact('log', ArtifactTypes.LOG),
  produceMultiFileArtifact('data', ArtifactTypes.RECON_DATA),
  produceMultiFileArtifact('doc', ArtifactTypes.DOCUMENTATION),
  produceMultiFileArtifact('tiff', ArtifactTypes.GEOTIFF),
]

/**
 * Creates all possible artifact prototypes from the list of analyzed files.
 */
// const groupFilesIntoProtoArtifacts: ProtoArtifactReducer = (state: ProtoArtifactReducerState)
export const groupFilesIntoProtoArtifacts = state => {
  return protoArtifactProducers.reduce((oldState, reducer) => reducer(oldState), state)
}

// const regenerateArtifactNames: ProtoArtifactReducer = (state: ProtoArtifactReducerState)
export const regenerateArtifactNames = state => ({
  files: state.files,
  protoArtifacts: state.protoArtifacts.map(protoArtifact => {
    const { artifactType, files } = protoArtifact
    return {
      ...protoArtifact,
      name: generateArtifactName(artifactType, files.map(f => f.file)),
    }
  }),
  missionId: state.missionId,
})

/**
 * Filters a list of `ProtoArtifact`s which are all sane
 * (meaning that no required files are missing and everything is okay).
 */
// function scrubInvalidProtoArtifacts(protoArtifacts: ProtoArtifact[]): ProtoArtifact[]
export function scrubInvalidProtoArtifacts (protoArtifacts) {
  return protoArtifacts.filter(protoArtifact => {
    const { files, fileSuggestions, artifactType } = protoArtifact
    // We keep artifacts which are suggestions (1),
    const isSuggestion = (fileSuggestions || []).length > 0
    // those which are camera artifacts ...
    const isCamArtifact = isCameraArtifact(artifactType)
    // ... and have a camFile (2), or are based on suggestions (covered by (1)),
    const hasCamFile = files.some(protoArtifactFile => isCamFileType(protoArtifactFile.fileType))
    // or those camera artifacts which have `Ladybug` sensor
    const hasLadybugSensor = isCameraArtifactHasLadybugSensor(protoArtifact)
    // and those non-cam artifacts which are not empty (3).
    const hasFiles = files.length > 0
    return isSuggestion ||
      (
        (isCamArtifact && hasCamFile) ||
        (isCamArtifact && hasLadybugSensor)
      ) ||
      (!isCamArtifact && hasFiles)
  })
}

/**
 * Removes the given file from all `ProtoArtifact`s and returns a list in which no `ProtoArtifact` contains that file.
 * Also remove file's props from the `fileProperties`.
 */
// function removeFileFromProtoArtifacts(protoArtifacts: ProtoArtifact[], file: File): ProtoArtifact[]
export function removeFileFromProtoArtifacts (protoArtifacts, file) {
  return protoArtifacts
    .map(protoArtifact => ({
      ...protoArtifact,
      files: protoArtifact.files.filter(protoArtifactFile => protoArtifactFile.file !== file),
      properties: {
        ...protoArtifact.properties,
        ...(protoArtifact.properties.fileProperties && {
          fileProperties: omit([file.name], protoArtifact.properties.fileProperties),
        }),
      },
    }))
    .filter(protoArtifact => protoArtifact.createdFrom !== file.name || protoArtifact.files.length > 0)
    .map(protoArtifact => protoArtifact.createdFrom === file.name
      ? { ...omit(['createdFrom'], protoArtifact), fileSuggestions: [] }
      : protoArtifact,
    )
    .map(protoArtifact => ({
      ...protoArtifact,
      name: generateArtifactName(protoArtifact.artifactType, protoArtifact.files.map(file => file.file)),
    }))
}

/**
 * Generate artifact name based on files in artifact
 */
export function generateArtifactName (artifactType, files) {
  if (files.length < 1) {
    return getDefaultArtifactName(artifactType)
  }
  if (files.length === 1) {
    return files[0].name
  }
  const sortedFiles = files.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
  return `${sortedFiles[0].name} - ${sortedFiles[sortedFiles.length - 1].name}`
}

/**
 * Returns a template artifact name, which contains the current date.
 */
export function getDefaultArtifactName (artifactType) {
  const dateString = new Date().toLocaleDateString()
  if (typeof artifactType === 'undefined') {
    return `Artifact - ${dateString}`
  }
  return `${prettifyArtifactType(artifactType)} - ${dateString}`
}

/**
 * Loop through the protoArtifacts and create the corresponding artifact for each one
 * @param {string} projectId
 * @param {array} protoArtifacts
 * @param {Object} missionIds
 */
export function * createArtifacts (projectId, protoArtifacts, missionIds) {
  const token = yield select(state => getToken(state))
  const headers = getAuthentificationHeader(token)
  const protoArtifactToArtifactMap = {}
  const tasks = []
  const nonEmptyProtoArtifacts = protoArtifacts.filter(protoArtifact => protoArtifact.files.length > 0)
  for (const protoArtifact of nonEmptyProtoArtifacts) {
    const { artifactType, name, missionId } = protoArtifact
    const properties = getArtifactProperties(protoArtifact)
    // Skip empty `Artifact`s.
    tasks.push(
      yield fork(
        axios.post,
        `/projects/${projectId}/artifacts`,
        {
          name,
          artifact_type: artifactType,
          properties: properties || {},
          mission_id: missionIds[missionId],
        },
        { headers },
      ),
    )
  }
  const artifacts = yield resolveTasks(tasks, convertRawArtifact)
  // TODO: Advanced error handling.
  artifacts.forEach((art, index) => {
    const protoArtifact = nonEmptyProtoArtifacts[index]
    if (protoArtifact) {
      protoArtifactToArtifactMap[protoArtifact.id] = art.id
    }
  })
  yield put(ArtifactsActions.putGetArtifacts(artifacts))
  return protoArtifactToArtifactMap
}

// Doesn't allow to user to upload files with the same names
// Returns an array of unique files and files that are not unique
export function makeFilesUnique (files) {
  const { uniqueFiles: hashMapOfFiles, unUniqueFiles } = files.reduce((allFiles, file) => {
    const fileName = file.name || file.file.name
    const isFileExist = allFiles.uniqueFiles[fileName]
    return isFileExist
      ? {
        ...allFiles,
        unUniqueFiles: [...allFiles.unUniqueFiles, file],
      }
      : {
        ...allFiles,
        uniqueFiles: {
          ...allFiles.uniqueFiles,
          [fileName]: file,
        },
      }
  }, {
    uniqueFiles: {},
    unUniqueFiles: [],
  })
  return {
    uniqueFiles: Object.keys(hashMapOfFiles).map(key => hashMapOfFiles[key]),
    unUniqueFiles,
  }
}

/**
 * Sort the artifacts in ASC order
 * @param {array} protoArtifacts
 */
export function sortProtoArtifactsBySize (protoArtifacts) {
  return protoArtifacts.sort((a, b) => {
    return a.files.reduce((size, file) => size + file.file.size, 0) >
      b.files.reduce((size, file) => size + file.file.size, 0)
  })
}

/**
 * Handle all the files
 * @param {array} protoArtifacts
 * @param {Object} protoArtifactToArtifactMap
 * @param {string} projectId
 */
export function * handleFiles (protoArtifacts, protoArtifactToArtifactMap, projectId) {
  const token = yield select(state => getToken(state))
  const headers = getAuthentificationHeader(token)
  // const artifactsInSizeOrder = sortProtoArtifactsBySize(protoArtifacts)
  let options = {}
  let onlyDropboxUploads = true
  for (const protoArtifact of protoArtifacts) {
    const { files, id, artifactType } = protoArtifact
    const artifactId = protoArtifactToArtifactMap[id]
    const autoComplete = true
    const dropboxFiles = files.filter(file => isDropboxFile(file))
    const otherFiles = files.filter(file => !isDropboxFile(file))
    if (isArtifactTypeUsingDirectoryApi(artifactType)) {
      const dataDirectory = yield call(createDataDirectory, headers, artifactId)
      options = {
        ...options,
        data_directory: {
          ...(options.data_directory || {}),
          [artifactId]: dataDirectory.id,
        },
      }
      yield put(ArtifactsActions.putGetDataDirectory(dataDirectory))
    }
    // If we have a files that uploaded from dropbox - we update the options
    if (dropboxFiles.length > 0) {
      options = {
        ...options,
        // We can add more files from the different uploaders in this array
        [artifactId]: dropboxFiles.map(file => file.file.path),
      }
    }
    // If we have a files from standard uploader
    if (otherFiles.length > 0) {
      onlyDropboxUploads = false
      yield put(UploadActions.startUpload(artifactId, autoComplete, undefined, projectId, otherFiles))
    }
  }
  return {
    options,
    onlyDropboxUploads,
  }
}

/**
 * Creates corresponding pipeline, jobs, job-runs
 * @param {Object} options
 */
export function * handleDropboxFiles ({ options, projectId, onlyDropboxUploads }) {
  const token = yield select(state => getToken(state))
  const headers = getAuthentificationHeader(token)
  const artifactIds = Object.keys(omit(['data_directory'], options))
  const isSomeOptionsExist = artifactIds.length > 0
  if (isSomeOptionsExist) {
    // Creating a pipeline
    const result = yield call(handleDropboxImportedFiles, {
      headers,
      projectId,
      options,
      onlyDropboxUploads,
      artifactId: artifactIds,
    })
    return result
  }
  return { okay: true }
}

export async function handleDropboxImportedFiles ({ headers, projectId, options, artifactId, onlyDropboxUploads }) {
  try {
    const lastAddedPipeline = await createPipeline(
      headers,
      projectId,
      i18n.t('importWizard.importFromDropboxPipelineName'),
      false,
    )
    // Creating a `upload_files` job
    const lastAddedJob = await createJob(headers, lastAddedPipeline.id, JobType.UPLOAD_FILES)
    const jobId = lastAddedJob.id
    // Creating a job run with those options
    const lastAddedJobRun = await createJobRun(headers, lastAddedJob.id, options)
    // Finally create the job ios using the prepared data.
    const artifactIds = Array.isArray(artifactId) ? artifactId : [artifactId]
    for (let i = 0; i < artifactIds.length; i++) {
      const artifactId_ = artifactIds[i]
      await createJobIO(headers, jobId, artifactId_, IOType.INPUT, {})
    }
    // If we have uploads only from the Dropbox we should redirect to the: created pipeline -> created job run
    if (onlyDropboxUploads) {
      history.push(routeProjectPipelinesJobRun(projectId, lastAddedPipeline.id, lastAddedJobRun.id))
    }
    return {
      pipeline: lastAddedPipeline,
      job: lastAddedJob,
      jobRun: lastAddedJobRun,
      okay: true,
    }
  } catch (e) {
    toast.error(i18n.t('toast.importWizard.importFromDropboxError'))
    return { okay: false }
  }
}

/**
 * Function can return custom properties for the properties of the artifact
 * @param {Object} protoArtifact
 */
export function getArtifactProperties (protoArtifact, state) {
  const { artifactType, files = [], properties } = protoArtifact
  if (!properties) {
    return {}
  }
  if (isPointcloudArtifact(artifactType)) {
    const { fileProperties = {}, files: pointcloudFiles, las_settings = {} } = properties
    const classifiedPointcloudFiles = path(['classified_pointcloud'], pointcloudFiles) || []
    const onlyTrajectoryFiles = Object.keys(fileProperties).filter(fileName => isTrjFileName(fileName))
    const onlyPointcloudFiles = Object.keys(fileProperties).filter(fileName => isLasFileName(fileName))
    const updateSettings = 'text' in (path(['fields_mapping'], las_settings) || {})
    const newLasSettingsValues = updateSettings ? omit(['uniqueKey', 'name'], getBackendTransformedValues(
      state,
      { ...las_settings.fields_mapping || {}, ...las_settings.visualization || {} },
      { ...las_settings.fields_mapping || {}, ...las_settings.visualization || {} },
      LasAllSettingsTemplate,
      true,
    )) : {}
    const protoArtifactWithUpdateTrajectories = onlyTrajectoryFiles
      .filter(fileName => shouldDisplayTrajectoryImportForm(fileName))
      .reduce(
        (proto, fileName) => {
          const { properties } = proto
          const { fileProperties } = properties
          const filePropertiesForFile = fileProperties[fileName] || {}
          const fieldNames = getTrajectoryCRSTemplateFieldNames(fileName)
          return {
            ...proto,
            properties: {
              ...properties,
              fileProperties: {
                ...fileProperties,
                [fileName]: {
                  ...(
                    fieldNames.every(fieldName => fieldName in filePropertiesForFile)
                      ? filePropertiesForFile
                      : {
                        ...filePropertiesForFile,
                        ...fieldNames.reduce((all, fieldName) => ({
                          ...all,
                          [fieldName]: protoArtifact.properties[fieldName],
                        }), {}),
                      }
                  ),
                },
              },
            },
          }
        },
        { ...protoArtifact, properties: omit(TrajectoryTemplateFieldNames, properties) })
    const newProtoArtifact = onlyPointcloudFiles
      .filter(fileName => !classifiedPointcloudFiles.includes(fileName))
      .reduce(
        (proto, fileName) => {
          const newCRSValues = omit(['uniqueKey', 'name'], getBackendTransformedValues(
            state,
            fileProperties[fileName],
            fileProperties[fileName],
            LasCRSTemplate,
            true,
          ))
          return {
            ...proto,
            properties: {
              ...proto.properties,
              fileProperties: {
                ...proto.properties.fileProperties,
                [fileName]: newCRSValues,
              },
            },
          }
        },
        protoArtifactWithUpdateTrajectories,
      )
    if (!updateSettings) {
      return {
        // ...properties,
        ...newProtoArtifact.properties,
        las_settings: las_settings,
        files: {
          classified_pointcloud: onlyPointcloudFiles,
          trajectories: onlyTrajectoryFiles,
        },
      }
    } else {
      return {
        // ...properties,
        ...newProtoArtifact.properties,
        las_settings: {
          ...(keepProps(LasSettingsFieldNames, newLasSettingsValues)),
          visualization: keepProps(LasVisualizationSettingsFieldsNames, newLasSettingsValues),
          fields_mapping: omit([...LasSettingsFieldNames, ...LasVisualizationSettingsFieldsNames], newLasSettingsValues),
        },
        files: {
          classified_pointcloud: onlyPointcloudFiles,
          trajectories: onlyTrajectoryFiles,
        },
      }
    }
  }
  if (isGCPArtifact(artifactType)) {
    // const newProtoArtifact = getNewGCPProtoArtifact(protoArtifact, state)
    // return getValuesByTab(newProtoArtifact.properties[CRSFields.TAB], newProtoArtifact.properties)
    const newCRSValues = omit(['uniqueKey', 'name'], getBackendTransformedValues(
      state,
      properties[GCP_BACKEND_NAME],
      properties[GCP_BACKEND_NAME],
      ProjectCRSTemplateInfo,
      true,
    ))
    return {
      ...properties,
      [GCP_BACKEND_NAME]: newCRSValues,
    }
  }
  if (isCameraArtifact(artifactType)) {
    const { interval, fileProperties } = properties
    if (interval) {
      return properties
    }
    if (fileProperties) {
      return {
        ...properties,
        interval: {
          ...Object.keys(fileProperties).reduce((interval, key) => {
            let propertyInterval = path(['result', 'interval'], fileProperties[key])
            if (propertyInterval) {
              propertyInterval = transformLegacyGpsTimeInterval(propertyInterval)
              return {
                beginning: {
                  tow: min(propertyInterval.beginning.tow, interval.beginning.tow),
                  week: min(propertyInterval.beginning.week, interval.beginning.week),
                },
                end: {
                  tow: max(propertyInterval.end.tow, interval.end.tow),
                  week: max(propertyInterval.end.week, interval.end.week),
                },
              }
            }
            return interval
          }, {
            beginning: {
              tow: Infinity,
              week: Infinity,
            },
            end: {
              tow: -Infinity,
              week: -Infinity,
            },
          }),
        },
      }
    }
  }
  if (isLidarArtifact(artifactType)) {
    const { fileProperties } = properties
    if (fileProperties) {
      return {
        ...properties,
        fileProperties: Object.keys(fileProperties).reduce((allFiles, fileName) => {
          const allProperties = fileProperties[fileName]
          const { interval, result } = allProperties
          const resultInterval = path(['interval'], result)
          let finalInterval = null
          if (!resultInterval && !isArtifactIntervalValid(resultInterval) && interval) {
            finalInterval = transformLegacyGpsTimeInterval(interval)
          } else {
            finalInterval = transformLegacyGpsTimeInterval(resultInterval)
          }
          return {
            ...allFiles,
            [fileName]: {
              ...allProperties,
              interval: finalInterval,
              result: {
                ...result,
                interval: finalInterval,
              },
            },
          }
        }, {}),
      }
    }
  }
  if (isTrajectoryArtifact(artifactType)) {
    const newValues = getBackendTransformedValues(
      state,
      properties,
      properties,
      getTrajectoryCRSTemplate(files),
    )
    return omit(['valid'], newValues)
  }
  if (isRefStationArtifact(artifactType)) {
    const newValues = getBackendTransformedValues(
      state,
      properties,
      properties,
      RefStationTemplate,
      true,
    )
    return omit(['valid'], newValues)
  }
  return properties
}

/**
 * Returns mission name based on already created missions
 * @param {array} missions
 */
export const getNewMissionName = missions => {
  const missionName = `Mission #1`
  const missionWithSuchName = missions.filter(mission => (mission.name || '').startsWith('Mission #'))
  // TODO: Should be improved or not?
  if (missionWithSuchName.length > 0) {
    const highestId = missionWithSuchName.reduce((highestId, mission) => {
      const splittedMissionName = (mission.name || '').split('#')
      const id = splittedMissionName[1]
      const parsedId = isNumber(id) ? +id : null
      return parsedId
        ? highestId > parsedId ? highestId : parsedId
        : highestId
    }, 1)
    return `Mission #${Math.floor(highestId + 1)}`
  }
  return missionName
}

const fileTypesMap = {
  IMAGE_FILE: 'images',
  KNOWN_FILE: 'known',
  UNKNOWN_FILE: 'unknown',
}

// Split files by types: images, files, otherFiles
export const splitFilesByTypes = (files, cameraArtifactsSuggestions) => {
  return files.reduce((allFiles, file) => {
    const fieldName =
      // Image file
      file.fileType === fileTypesMap.UNKNOWN_FILE && isFileImage(file.file, cameraArtifactsSuggestions)
        ? fileTypesMap.IMAGE_FILE
      // File with known type
        : file.fileType !== fileTypesMap.UNKNOWN_FILE
          ? fileTypesMap.KNOWN_FILE
        // File with unknown type
          : fileTypesMap.UNKNOWN_FILE
    return {
      ...allFiles,
      [fieldName]: [
        ...allFiles[fieldName],
        file,
      ],
    }
  }, {
    [fileTypesMap.IMAGE_FILE]: [],
    [fileTypesMap.KNOWN_FILE]: [],
    [fileTypesMap.UNKNOWN_FILE]: [],
  })
}

export const isDropboxFile = file => {
  return file.file.uploadType === UploaderType.DROPBOX
}
