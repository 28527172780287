// Local deps
import { DataType } from 'types/form'
import { getCloneJobOptionValue } from '../utils'
import { MapBackendNameToFrontend } from '../constants'
import { isAdmin } from 'modules/users/selectors'
import { validateCommitHash } from 'components/TemplatedForm/utils'
import { getPipelineTemplate } from 'modules/pipelineWizard/selectors'
import { PipelineWizardType } from 'types/pipelines'

export default {
  secore_git_commit: {
    name: MapBackendNameToFrontend.secore_git_commit,
    dataType: DataType.STRING,
    onValidate: (state, value) => validateCommitHash(value),
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'secore_git_commit',
      '',
    ),
    optional: (state, values) => true,
    invisible: (state, values) => !isAdmin(state),
    sendToBackend: (state, values) => !!values.secore_git_commit,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  worker_host: {
    name: MapBackendNameToFrontend.worker_host,
    dataType: DataType.STRING,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'worker_host',
      '',
    ),
    optional: (state, values) => true,
    invisible: (state, values) => !isAdmin(state),
    sendToBackend: (state, values) => !!values.worker_host,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  custom: {
    name: MapBackendNameToFrontend.custom,
    dataType: DataType.JSON,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'custom',
      { json: {}, error: null },
    ),
    optional: (state, values) => !values.custom.error,
    invisible: (state, values) => !isAdmin(state),
    backendTransform: (original, state) => original.json || original,
    sendToBackend: (state, values) => {
      return values.custom && (
        'json' in values.custom
          ? Object.keys(values.custom.json).length > 0
          : Object.keys(values.custom).length > 0)
    },
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  profile_views: {
    name: MapBackendNameToFrontend.profile_views,
    dataType: DataType.JSON,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'profile_views',
      { json: [], error: null },
    ),
    optional: (state, values) => {
      const pipelineTemplate = getPipelineTemplate(state)
      return !values.profile_views.error || pipelineTemplate.type !== PipelineWizardType.SPATIAL_FUSER
    },
    invisible: (state, values) => {
      const pipelineTemplate = getPipelineTemplate(state)
      return !isAdmin(state) || pipelineTemplate.type !== PipelineWizardType.SPATIAL_FUSER
    },
    backendTransform: (original, state) => original.json || original,
    sendToBackend: (state, values) => {
      return values.profile_views && (
        'json' in values.profile_views
          ? Object.keys(values.profile_views.json).length > 0
          : Object.keys(values.profile_views).length > 0)
    },
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
}
