import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
export const Loader = props => {
  const { style, size = 80 } = props
  const styles = useStyles()
  return (
    <div className={style || styles.root}>
      <CircularProgress size={size} />
    </div>
  )
}

Loader.propTypes = {
  style: PropTypes.object,
  size: PropTypes.number,
}