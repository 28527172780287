// turf
// Project deps
import { JobType } from 'types/jobs'
// import { getArtifacts } from '../modules/projects/selectors'
// Local deps
import OutputCRSTemplate from './CRS/OutputCRSJobTemplate'

import Products from './jobOptions/products'
import GroundClassification from './jobOptions/groundClassification'
import Optimization from './jobOptions/optimization'
import Flightlines from './jobOptions/flightlines'
import LidarSettings from './jobOptions/lidarSettings'
import Calibration from './jobOptions/calibration'
import Classification from './jobOptions/classification'
import Imu from './jobOptions/imu'
import NavLab from './jobOptions/navlab'
import PipelineSettings from './jobOptions/pipelineSettings'
import PointcloudOptimization from './jobOptions/pointcloudOptimization'
import PointcloudOptimizationStep from './jobOptions/pointcloudOptimizationStep'
import OrthoimageGeneration from './jobOptions/orthoimageGeneration'
import DeveloperSettings from './jobOptions/developerSettings'

export const templateJobOptions = {
  [JobType.NAVLAB]: NavLab,
  [JobType.PIPELINE_SETTINGS]: PipelineSettings,
  [JobType.CLASSIFICATION]: Classification,
  [JobType.OUTPUT_CRS]: OutputCRSTemplate,
  [JobType.CALIBRATION]: Calibration,
  [JobType.IMU]: Imu,
  [JobType.LIDAR_SETTINGS]: LidarSettings,
  [JobType.FLIGHTLINES]: Flightlines,
  [JobType.OPTIMIZATION]: Optimization,
  [JobType.GROUND_CLASSIFICATION]: GroundClassification,
  [JobType.PRODUCTS]: Products,
  [JobType.POINTCLOUD_OPTIMIZATION]: PointcloudOptimization,
  [JobType.OPTIMIZATION_POINTCLOUD]: PointcloudOptimizationStep,
  [JobType.ORTHOIMAGE_GENERATION]: OrthoimageGeneration,
  [JobType.DEVELOPER_SETTINGS]: DeveloperSettings,
  /*
  [JobType.LOCAL_CRS]: {
    local_crs: {
      name: 'Local CRS',
      dataType: DataType.STRING,
      optional: true,
      precision: 2,
      invisible: (state, values) => !isAdmin(state),
      initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'local_crs', ''),
      backendName: undefined,
    },
  },
  */
}
