import { omit } from 'ramda'
import i18n from 'i18n'
import { getDatums, getIEDatums, getRecommendedDatums } from 'modules/app/selectors'
import { getSelectedReferenceStationDatums } from 'modules/pipelineWizard/selectors'
// Local deps
import { onChangePosition } from '../CRS/utils'
import {
  coordinateFieldDisabled,
  isCustomAnalyzeSelected,
  isCustomAnalyzeTypeSelected,
  selectFromAnalyzed,
  getCloneArtifactOptionValue,
} from '../utils'
import { DataType } from 'types/form'
import RefStationCRSTemplate from '../CRS/refStationCRSTemplate'
import { tabsTemplate } from '../CRS/fields'
import {
  createAnalyzeEntryMapping,
} from '../jobIoOptionsUtils'
import { MapBackendNameToFrontend } from 'templates/constants'
import { getCurrentProject } from 'modules/projects/selectors'
import { makeUniqueById } from 'utils/list'

const getTransformedEntry = entry => {
  return { ...(entry || {}), newDescription: `${(entry || {}).description}${entry && entry.type !== 'custom' ? ' (Recommended)' : ''}` }
}

/**
 * Sorts all positions by priority and estimated error:
 * 1) Position with lower priority should be on top.
 * 2) Position with lower error should be on top.
 * @param {Array} entries ref station positions
 * @returns {Array} sorted array
 */
const getMostPrecisePosition = entries => {
  return entries
    .reduce((b, a) => a.priority < b.priority
      ? a
      : a.priority === b.priority
        ? a.estimatedErrorRms < b.estimatedErrorRms
          ? a
          : a.estimatedErrorRms === b.estimatedErrorRms ? a : b
        : b
    , { priority: Infinity, estimatedErrorRms: Infinity, index: -1 })
}

const sortRefStationPositions = (ieDatums, entries) => {
  const entriesUpdatedPriorities = entries.map(entry => {
    const datumPriority = (ieDatums.find(d => d.datum.navlab_datum === entry.datum) || { priority: 1 }).priority
    return {
      ...entry,
      priority: entry.priority * datumPriority,
    }
  })
  return entriesUpdatedPriorities.sort((a, b) => {
    const vals = [a, b].map((entry, index) => ({ ...entry, index }))
    const mostPrecised = getMostPrecisePosition(vals)
    if (mostPrecised.index === 0) {
      return -1
    } else {
      return 1
    }
  })
}

const getAnalyzeInitialValue = (state, artifactId, options) => {
  const { extraProps } = options
  const { projectCRS, clone } = extraProps
  const allIeDatums = getIEDatums(state)
  let analyzeEntries = createAnalyzeEntryMapping(state, artifactId)
  const epsgDatums = []
  // Position with project datum are most prioritized
  if (projectCRS && projectCRS.datum) {
    epsgDatums.push({ datum: projectCRS.datum, priority: 0.01 })
  }
  const currentProjectInfo = getCurrentProject(state)
  const project = currentProjectInfo.project
  const gpsPosition = project.gpsPosition
  // Position with recommended datum are prioritized after project datum
  if (gpsPosition && typeof gpsPosition.lat === 'number' && typeof gpsPosition.lng === 'number') {
    const [recommendedEPSGDatum] = getRecommendedDatums(state, gpsPosition.lat, gpsPosition.lng)
    if (recommendedEPSGDatum) {
      epsgDatums.push({ datum: recommendedEPSGDatum, priority: 0.1 })
    }
  }
  // Position with previous ref station datum are prioritized after recommended datum
  const selectedReferenceStationDatums = getSelectedReferenceStationDatums(state)
  const [firstDatum] = Object.keys(selectedReferenceStationDatums)
  // We can use whole set of datums here but just stick with the first one for now
  const refStationDatums = [firstDatum]
  refStationDatums.filter(Boolean).forEach((datum, index) => {
    const ieDatum = allIeDatums.find(ieDatum => ieDatum.navlab_datum === datum)
    if (ieDatum) {
      epsgDatums.push({
        priority: 0.5 * index,
        datum: ieDatum,
        isIEDatum: true,
      })
    }
  })
  const ieDatums = epsgDatums
    .map(epsgDatum => ({
      ...epsgDatum,
      id: epsgDatum.datum.name,
      datum: epsgDatum.isIEDatum ? epsgDatum.datum : allIeDatums.find(iedatum => iedatum.name === epsgDatum.datum.name),
    }))
  // Remove all repeatable datums and datums for which didn't found any ie datum
  const eligableIeDatums = makeUniqueById(ieDatums).filter(ieDatum => Boolean(ieDatum.datum))
  analyzeEntries = sortRefStationPositions(eligableIeDatums, analyzeEntries)
  // First position now is always the most prioritized
  const [mostPreciseEntry] = analyzeEntries

  // When cloning most prioritized value is cloned position
  // But we still need to prioritize other entries in the list that's why this step is at the end
  if (clone) {
    const value = getCloneArtifactOptionValue(state, artifactId, options, 'analyze')
    const datum = getCloneArtifactOptionValue(state, artifactId, options, 'datum')
    const epoch = getCloneArtifactOptionValue(state, artifactId, options, 'epoch')
    if (value) {
      const analyzeEntry = analyzeEntries.find(entry =>
        entry.description === value ||
        (
          (entry.name || '').toString() === value.toString() &&
          (entry.datum || '').toString() === datum.toString() &&
          (entry.epoch || '').toString() === epoch.toString()
        ),
      )
      return {
        initialValue: getTransformedEntry(analyzeEntry),
        entries: analyzeEntries,
      }
    }
  }

  return {
    initialValue: getTransformedEntry(mostPreciseEntry),
    entries: analyzeEntries,
  }
}

export default {
  text: {
    name: 'warning',
    variant: 'warning',
    dataType: DataType.TEXT,
    initialValue: i18n.t('templates.artifactOptions.referenceStation.warning.transform'),
    invisible: (state, values, extra, formTemplate, option, { extraProps = {} }) => {
      const { projectCRS } = extraProps
      if (isCustomAnalyzeSelected(values)) return true
      if (projectCRS && projectCRS.datum) {
        const datum = getIEDatums(state).find(iedatum => iedatum.name === projectCRS.datum.name)
        if (datum && datum.navlab_datum) {
          return values.analyze.datum === datum.navlab_datum
        }
      }
      return true
    },
    sendToBackend: false,
  },
  // In this field the user will select the source of the coordinates for the reference station.
  // The user can select either a precomputed set of coordinates from either:
  //  * OPUS
  //  * PPP
  //  * Average
  //  * Rinex
  //  * Сenterpoint RTX
  // or "Custom", to input the coordinates manually.
  // The precomputed sets of coordinates will be taken from the artifact's properties.
  // They are not necessarily all defined.
  analyzeInitialValue: {
    name: MapBackendNameToFrontend.analyze,
    dataType: DataType.AUTOCOMPLETE,
    invisible: true,
    optional: true,
    initialValue: (state, artifactId, options) => {
      const { initialValue } = getAnalyzeInitialValue(state, artifactId, options)
      return initialValue
    },
    sendToBackend: false,
  },
  analyze: {
    name: MapBackendNameToFrontend.analyze,
    displayTemplate: entry => entry.newDescription,
    searchTemplate: entry => entry.newDescription,
    getValue: (state, value, values, extra) => value ? value.newDescription : '',
    options: (state, values, artifactId, options) => {
      const { entries } = getAnalyzeInitialValue(state, artifactId, options)
      const initialValue = values.analyzeInitialValue
      /*
      if (initialValue.name === 'Centerpoint RTX') {
        return entries.filter(entry => {
          const { name, analyzeMode } = entry
          return initialValue.name !== name || initialValue.analyzeMode === analyzeMode
        })
          .map(entry => entry.description === initialValue.description ? getTransformedEntry(entry) : entry)
          .sort((a, b) => a.priority - b.priority)
      }
      */
      return [
        initialValue,
        // Remove initial value entry from the list to not repeat it
        ...entries
          .filter(entry => entry.analyzeMode !== initialValue.analyzeMode)
          .map(entry => entry.description === initialValue.description ? getTransformedEntry(entry) : entry),
      ]
    },
    initialValue: (state, artifactId, options) => {
      return options.values.analyzeInitialValue
    },
    onChange: onChangePosition(tabsTemplate),
    disableSearch: true,
    dataType: DataType.AUTOCOMPLETE,
    backendTransform: (original, state, formValues) => original.name || original.description,
    /*
    dataType: DataType.SELECTION,
    options: (state, _values, artifactId) => {
      const analyzeEntryMapping = createAnalyzeEntryMapping(state, artifactId)
      return analyzeEntryMapping.map(entry => entry.description)
    },
    initialValue: (state, artifactId) => {
      const analyzeEntryMapping = createAnalyzeEntryMapping(state, artifactId).sort((a, b) => a.priority - b.priority)
      const mostPrecise = analyzeEntryMapping
        .reduce((b, a) => (
          a.estimatedErrorRms < b.estimatedErrorRms &&
          a.priority <= b.priority &&
          a.estimatedErrorRms
        ) ? a : b)
      return mostPrecise.description
    },
    */
    // No mapping is required, because the human-readable repersentations are used.
  },
  datum: {
    name: MapBackendNameToFrontend.datum,
    dataType: DataType.SELECTION,
    invisible: (state, formValues) => !coordinateFieldDisabled(state, formValues),
    options: state => getDatums(state).map(datum => datum.navlab_datum),
    optional: (state, values, extra) => isCustomAnalyzeTypeSelected(values),
    disabled: coordinateFieldDisabled,
    initialValue: (state, extra, options) => {
      const { extraProps = {} } = options
      const { clone } = extraProps
      if (clone) return getCloneArtifactOptionValue(state, extra, options, 'datum', 'WGS84')
      return 'WGS84'
    },
    transformOnChangeOf: ['analyze'],
    transform: (original, state, formValues, artifactId) => {
      const datum = selectFromAnalyzed(original, state, formValues, artifactId, 'datum')
      if (datum === 'ITRF2008') return 'ITRF08'
      return datum
    },
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
    sendToBackend: (state, values) => !isCustomAnalyzeSelected(values),
  },
  ...omit(['analyze'], RefStationCRSTemplate),
}
