// Internal dependencies.
import { omit } from 'ramda'
import i18n from 'i18n'
// Project deps
import { FrontendPositionCoordinates } from 'types/position'
import { getGCPsForArtifact } from 'modules/importWizard/selectors'
import { getAntennas } from 'modules/app/selectors'
import { DataType } from 'types/form'
import {
  coordinateFieldDisabled,
  selectFromAnalyzed,
  getValueFromArtifact,
  getTransformedField,
  getAntennaOptions,
  getAntennaOption,
  getCloneArtifactOptionValue,
  isCustomAnalyzeSelected,
  addFavoriteOptionToCookie,
  getFavouriteOptions,
} from 'templates/utils'
import { coordinatesTemplate, transformedCoordinatesTemplate } from 'templates/coordinatesTemplate'
// Local deps
import { tabsTemplate } from './fields'
import { CRSFields } from './constants'
import { onChangePosition, onGetTransformedPoints, updateCoordinatesChooser } from './utils'
import {
  REF_STATION_BACKEND_NAME,
  backendProcessorToFrontendReferenceStationAntennaName,
  processorToFrontendReferenceStationAntennaName,
  antennaInitialValue,
  Coordinates,
  HeightAntennaMapping,
  HeightAntennaOptions,
  CoordinateTypes,
  MapBackendNameToFrontend,
} from 'templates/constants'
import { getCloneArtifactOptions } from 'modules/pipelineWizard/selectors'
import { getBackendTransformedValues } from 'utils/templates'
import { ProjectCRSTemplateInfo } from './ProjectCRS/ProjectCRSTemplateInfo'
import { getProjectCRSInitialValues } from './ProjectCRS/utils'
import { getAntennaTemplate } from '../jobIoOptionsUtils'
import { Cookies } from 'utils/constants'

export default {
  analyze: {
    name: MapBackendNameToFrontend.analyze,
    invisible: true,
    dataType: DataType.AUTOCOMPLETE,
    initialValue: (state, artifactId, { extraProps = {} }) => ({
      type: 'custom',
    }),
    onChange: onChangePosition(tabsTemplate),
  },
  [REF_STATION_BACKEND_NAME]: {
    name: 'CRS',
    dataType: DataType.CRS,
    refStation: true,
    optional: (state, values) => !isCustomAnalyzeSelected(values),
    invisible: (state, values) => !isCustomAnalyzeSelected(values),
    initialValue: (state, extra, { extraProps }) => {
      const { projectCRS, clone } = extraProps
      if (clone) {
        const artifactOptions = getCloneArtifactOptions(state, extra)
        if (artifactOptions[REF_STATION_BACKEND_NAME]) {
          const crs = getProjectCRSInitialValues(state, artifactOptions[REF_STATION_BACKEND_NAME])
          return crs
        }
        return null
      }
      if (projectCRS) {
        const crs = getProjectCRSInitialValues(state, projectCRS)
        return crs
      }
      return null
    },
    backendTransform: (original, state, values) => {
      if (isCustomAnalyzeSelected(values)) {
        return omit(['name'], getBackendTransformedValues(state, original, original, ProjectCRSTemplateInfo))
      }
      return null
    },
    onChange: (name, value, options) => {
      const { state, setMultipleValues } = options
      updateCoordinatesChooser(state, setMultipleValues, value, value)
    },
  },
  /*
  [CRSFields.GRID]: CRSTemplate[CRSFields.GRID],
  ...omit([CRSFields.EPOCH, CRSFields.GRID], CRSTemplate),
  [CRSFields.TAB]: {
    ...CRSTemplate[CRSFields.TAB],
    initialValue: (state, extra, options) => {
      return getInitialTab(state, extra, options)
    },
  },
  [CRSFields.LAST_CHANGED_TAB]: {
    ...CRSTemplate[CRSFields.LAST_CHANGED_TAB],
    initialValue: (state, extra, options) => {
      return getInitialLastChangedTab(state, extra, options)
    },
  },
  */
  [CRSFields.COORDINATES_CHOOSER]: {
    name: 'Coordinates type',
    dataType: DataType.SELECTION,
    invisible: true,
    invisibleForTab: true,
    optional: true,
    options: [CoordinateTypes.LAT_LON, CoordinateTypes.NORTHING_EASTING, CoordinateTypes.EASTING_NORTHING],
    initialValue: CoordinateTypes.NORTHING_EASTING,
    sendToBackend: false,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  ...coordinatesTemplate,
  ...transformedCoordinatesTemplate,
  antennaName: {
    name: MapBackendNameToFrontend.antennaName,
    dataType: DataType.ANTENNA_AUTOCOMPLETE,
    tooltip: MapBackendNameToFrontend.tooltips.antennaName,
    tooltipProps: { enterDelay: 500 },
    viewLimit: 4,
    displayTemplate: getAntennaTemplate,
    searchTemplate: getAntennaTemplate,
    getValue: (state, value, values, extra) => {
      addFavoriteOptionToCookie(value, Cookies.favoriteAntennas.amount, Cookies.favoriteAntennas.name)
      return getAntennaTemplate(value)
    },
    compare: (suggestion, selectedItem) => suggestion.searchValue === selectedItem.searchValue,
    options: (state) => getFavouriteOptions(state, Cookies.favoriteAntennas.name),
    disabled: coordinateFieldDisabled,
    initialValue: (state, artifactId, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      if (clone) {
        const value = getCloneArtifactOptionValue(state, artifactId, options, 'antennaName')
        if (value) return getAntennaOptions(state).find(antenna => antenna.value === value)
      }
      return antennaInitialValue
    },
    backendTransform: (original, state, formValues) => original.value,
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => {
        const antennaName = analyzeData['antennaName']
        const antennas = getAntennas(state)
        const antennaIndex = antennas.findIndex(antenna => antenna.name === antennaName)
        const antenna = antennaIndex >= 0 ? antennas[antennaIndex] : null
        return antenna ? getAntennaOption(antenna, antennaIndex) : antenna
      },
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => {
        const analyze = formValues.analyze
        if (analyze.antenna) {
          const antennas = getAntennas(state)
          const antennaIndex = antennas.findIndex(({ name, radome }) =>
            name === analyze.antenna.name &&
            radome === analyze.antenna.radome,
          )
          if (antennaIndex >= 0) {
            return getAntennaOption(antennas[antennaIndex], antennaIndex)
          }
        }
        return antennaInitialValue
      },
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  },
  antennaRadome: {
    dataType: DataType.STRING,
    invisible: true,
    optional: true,
    initialValue: null,
    sendToBackend: true,
    backendTransform: (original, state, formValues) => {
      if (formValues.antennaName) {
        return formValues.antennaName.radome || null
      }
      return null
    },
  },
  heightAntenna: {
    name: MapBackendNameToFrontend.heightAntenna,
    dataType: DataType.FLOAT,
    precision: 3,
    disabled: (state, formValues) => coordinateFieldDisabled(state, formValues),
    initialValue: (state, artifactId, options) => getCloneArtifactOptionValue(state, artifactId, options, 'heightAntenna', '0.0'),
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        analyzeData['heightAntenna'],
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        selectFromAnalyzed(original, state, formValues, extra, 'heightAntenna'),
    ),
    /*
    transform: (original, state, formValues, artifactId, formTemplate, name, oldValues) => {
      const fieldName = 'heightAntenna'
      const analyze = formValues[name]
      const analyzeData = analyze && (analyze.data || analyze)
      const analyzedTypeDataValue = selectFromAnalyzed(original, state, oldValues, artifactId, fieldName)
      // If we select user custom position
      if (isUserCustomAnalyzeTypeSelected(formValues) && analyzeData) return analyzeData[fieldName]
      // If we selected one of predefined positions
      if (isAnalyzedTypeSelected(formValues)) return analyzedTypeDataValue
      // If we choose 'Custom' position
      if (isCustomAnalyzeTypeSelected(formValues)) {
        // And the previous one was one of the predefined positions
        if (isAnalyzedTypeSelected(oldValues)) return analyzedTypeDataValue
      }
    },
    */
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  heightAntennaReference: {
    name: MapBackendNameToFrontend.measuredTo,
    dataType: DataType.SELECTION,
    options: HeightAntennaOptions,
    mapping: HeightAntennaMapping,
    disabled: (state, formValues) => coordinateFieldDisabled(state, formValues),
    initialValue: (state, artifactId, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      if (clone) {
        const value = getCloneArtifactOptionValue(state, artifactId, options, 'heightAntennaReference')
        if (value) {
          return backendProcessorToFrontendReferenceStationAntennaName[value]
        }
      }
      const key = getValueFromArtifact(state, artifactId, ['antenna', 'heightAntennaReference'])
      return processorToFrontendReferenceStationAntennaName[key]
    },
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => {
        const value = analyzeData['heightAntennaReference']
        return backendProcessorToFrontendReferenceStationAntennaName[value] || value
      },
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        selectFromAnalyzed(original, state, formValues, extra, 'heightAntennaReference'),
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => {
        const value = selectFromAnalyzed(original, state, formValues, extra, 'heightAntennaReference')
        return processorToFrontendReferenceStationAntennaName[value]
      },
    ),
    backendTransform: (original) => {
      if (!Object.values(HeightAntennaMapping).includes(original)) return HeightAntennaMapping[original]
      return original
    },
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  refStationPosition: {
    dataType: DataType.REF_STATION_POSITION,
    sendToBackend: false,
  },
  cesiumMap: {
    dataType: DataType.CESIUM_MAP,
    props: {
      height: '100%',
      width: '100%',
    },
    initialValue: (state, extra, options) => {
      const { actions, values, extraProps } = options
      const { clone } = extraProps
      if (clone) {
        const crs = values[REF_STATION_BACKEND_NAME]
        if (isCustomAnalyzeSelected(values)) {
          const point = {
            x: values[Coordinates.LONGITUDE],
            y: values[Coordinates.LATITUDE],
            z: values[Coordinates.ALTITUDE],
          }
          onGetTransformedPoints(state, extra, actions, point, crs, crs)
        }
      }
    },
    getValue: (state, value, values, extra) => {
      if (coordinateFieldDisabled(state, values)) {
        return [{
          name: i18n.t('templates.artifactOptions.referenceStation.singlePoint'),
          description: '',
          coordinates: {
            lon: +values[Coordinates.LONGITUDE],
            lat: +values[Coordinates.LATITUDE],
            height: +values[Coordinates.ALTITUDE],
          },
        }]
      }
      const points = getGCPsForArtifact(state, extra) || []
      return points.map((point, index) => ({
        name: i18n.t('templates.artifactOptions.referenceStation.singlePointIndex', { index }),
        description: '',
        coordinates: {
          lon: point[FrontendPositionCoordinates.LONGITUDE],
          lat: point[FrontendPositionCoordinates.LATITUDE],
          height: point[FrontendPositionCoordinates.ALTITUDE],
        },
      }))
    },
  },
  epoch: {
    dataType: DataType.STRING,
    optional: true,
    invisible: true,
    initialValue: (state, artifactId, options) => {
      const { extraProps, values } = options
      const { clone } = extraProps
      if (clone) {
        const value = getCloneArtifactOptionValue(state, artifactId, options, 'epoch')
        if (value) {
          return value
        }
      }
      const { analyze } = values
      return analyze.epoch || null
    },
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => analyzeData['epoch'],
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) => selectFromAnalyzed(original, state, formValues, extra, 'epoch') || null,
    ),
  },
  coordinate_chooser_initial_value_init: {
    optional: true,
    invisible: true,
    sendToBackend: false,
    initialValue: (state, extra, { extraProps, values, onChange, name }) => {
      const { clone } = extraProps
      if (clone) {
        updateCoordinatesChooser(
          state,
          undefined,
          values[REF_STATION_BACKEND_NAME],
          values[REF_STATION_BACKEND_NAME],
          { onChange, values },
        )
      }
      return ''
    },
  },
}
