import { Map } from 'immutable'

export const INITIAL_STATE = Map({
  preselect: true,
  step: 0,
  wizardStep: 0,
  configureStep: 0,
  jobOptions: {},
  artifactOptions: {},
  cloningPipeline: false,
  cloneOptions: {
    jobOptions: {},
    artifactOptions: {},
    jobRunOptions: {}
  },

  selectedTemplateArtifacts: {},
  selectedArtifactsByType: {},
  selectedDatumsByType: {},

  pipelineTemplate: undefined,
  createPipelineIsLoading: false,
  pipelineCreation: {
    creationStage: null,
    okay: false,
    errorMessage: null,
  },
  pipelineId: null,

  additionalJobRunOptionsByArtifacts: {},
  additionalJobIoOptionsByArtifacts: {},

  navIntervalIndex: {},
  doneSteps: {},

  gcps: {},
  getGCPIsLoading: false,
  getGCPError: null,
})
