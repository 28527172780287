/**
 * Shortcut for searching in lists where the items have an `id` field.
 * Same semantics as `Array.find`, returns `undefined` when no element found.
 */
export function findById (id, entries = []) {
  return entries.find(entry => entry.id === id)
}

export function filterByMissionId (missionId, entries) {
  return entries.filter(entry => entry.missionId === missionId)
}

export function findByMissionId (missionId, entries) {
  return entries.find(entry => entry.missionId === missionId)
}

export function filterByArtifactId (artifactId, entries) {
  return entries.filter(entry => entry.artifactId === artifactId)
}

export function findByArtifactId (artifactId, entries) {
  return entries.find(entry => entry.artifactId === artifactId)
}

export function filterByProjectId (projectId, entries) {
  return entries.filter(entry => entry.projectId === projectId)
}

// Similar to regular reduce, with the exception that if the `merge` function returns `undefined`,
// the list value is put into a list of unreduced values, which is returned separately.
// function selectiveReduce<A, B>(
//   merge: (a: A, b: B, index: number) => A | undefined,
//   base: A,
//   list: B[]
// ): { readonly result: A, readonly unused: B[] }
export function selectiveReduce (merge, base, list) {
  return list.reduce(
    ({ result, unused }, element, index) => {
      const newResult = merge(result, element, index)
      return typeof newResult === 'undefined'
        ? { result, unused: [...unused, element] }
        : { result: newResult, unused }
    },
    { result: base, unused: [] },
  )
}

export function concatMap (transform, list) {
  return list.map(transform).reduce((a, b) => [ ...a, ...b ], [])
}

// Remove all duplicates from the `array`.
// function partition<T>(predicate: (elem: T) => boolean, array: T[]): { true: T[], false: T[] }
export function partition (predicate, array) {
  return {
    true: array.filter(predicate),
    false: array.filter(elem => !predicate(elem)),
  }
}

// function reducePartition<T>(predicate: (elem: T) => boolean, array: T[]): { true: T[], false: T[] }
export function reducePartition (predicate, array) {
  return array.reduce((accum, elem) =>
    predicate(elem)
      ? { ...accum, true: [...accum.true, elem] }
      : { ...accum, false: [...accum.false, elem] },
  { true: [], false: [] })
}

export function areArraysEqual (a, b) {
  return a.length === b.length && a.every((value, index) => value === b[index])
}

// Turns an array of objects into a dict of objects keyed by
// the value indexKey.
// A little nasty but I can't figure out how to make it work
// via a map() or such.
export function arrayToDict (array, indexKey) {
  const normalizedObject = {}
  for (let i = 0; i < array.length; i++) {
    const key = array[i][indexKey]
    normalizedObject[key] = array[i]
  }
  return normalizedObject
}

/**
 * This utility function merges a new entry into a list. It uses the `id` field of the
 * list elements. It removes all elements from `oldList` which have the same `id` as
 * newEntry and inserts `newEntry` into the list.
 * @param oldList The old list.
 * @param newEntry Element to add to the old list.
 * @return A new list containing `newEntry` and those entries from `oldList` which
 * have a different `id`.
 */
// function insertById<I, T extends HasId<I>>(oldList: T[], newEntry: T): T[]
export function insertById (oldList, newEntry) {
  const unchangedOldEntries = oldList.filter(item => item.id !== newEntry.id)
  return [...unchangedOldEntries, newEntry]
}

/**
 * Shortcut for checking whether an object with `id` exists in the list `entries`.
 */
export function existsById (id, entries) {
  return entries.some(entry => entry.id === id)
}

/**
 * Executes `transform` on the element with the id `id`.
 *
export function mapById<I, T extends HasId<I>>(
    id: I,
    entries: T[],
    transform: (t: T) => T
): T[] {
    return entries.map(entry => entry.id === id ? transform(entry) : entry);
    }
    */
export function mapById (id, entries, transform) {
  return entries.map(entry => entry.id === id ? transform(entry) : entry)
}

/**
 * Converts a `FileList` object into a `File[]` array.
 *
export function fileListToArray(fileList: FileList): File[] {
    const files: File[] = [];
    // `FileList` does not extend array, so it is neccessary to iterate it the good old way.
    for (let i = 0; i < fileList.length; ++i) {
        const file = fileList.item(i);
        files.push(file);
    }
    return files;
    }
    */
export function fileListToArray (fileList) {
  const files = []
  for (let i = 0; i < fileList.length; ++i) {
    const file = fileList.item(i)
    files.push(file)
  }
  return files
}

export function concatenate (list) {
  return list.reduce((a, b) => [ ...a, ...b ], [])
}

/**
 * Removes the element with the id `id` from the list.
 */
// export function removeById<I, T extends HasId<I>>(id: I, entries: T[]): T[] {
export function removeById (id, entries) {
  return entries.filter(entry => entry.id !== id)
}

export function removeByMissionId (id, entries) {
  return entries.filter(entry => entry.missionId !== id)
}

export function removeByIdOnce (id, entries) {
  const index = entries.findIndex(entry => entry.id === id)
  return [...entries.slice(0, index), ...entries.slice(index + 1, entries.length)]
}

export function removeByField (id, entries, fieldName) {
  return entries.filter(entry => entry[fieldName] !== id)
}

/**
 * This utility function merges two lists. It uses the `id` field of the list elements.
 * It retains all elements from `oldList` for which there is no element in `newEntries`
 * which has the same `id`.
 * @param oldList The old list.
 * @param newEntries Elements to add to the old list.
 * @return A new list containing all the entries from `newEntries` and those entries from
 *     `oldList` which were not replaced.
 */
// export function mergeById<I, T extends HasId<I>>(oldList: T[], newEntries: T[]): T[] {

export function mergeById (oldList, newEntries) {
  const unchangedOldEntries = oldList.filter(item => newEntries.every(newItem => item.id !== newItem.id))
  return [...unchangedOldEntries, ...newEntries]
}

export function makeUnique (array) {
  return array.reduce((accum, element) => accum.indexOf(element) === -1 ? [ ...accum, element ] : accum, [])
}

export function makeUniqueById (array) {
  return array.reduce((accum, element) =>
    !accum.find(elem => elem.id === element.id)
      ? [ ...accum, element ]
      : accum,
  [],
  )
}
