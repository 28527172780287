import React from 'react'
import i18n from 'i18n'
import { Trans } from 'react-i18next'
import { path, equals } from 'ramda'
import * as turf from '@turf/turf'
// turf
// Project deps
import {
  getJobOptions,
  getPipelineTemplate,
  getSelectedCameraArtifacts,
  getSelectedTrajectoryArtifacts,
  // getSelectedTemplateArtifacts,
} from 'modules/pipelineWizard/selectors'
// import { getArtifacts } from '../modules/projects/selectors'
// Local deps
import { DataType } from 'types/form'
import { getCloneJobOptionValue } from '../utils'
// import { findById } from '../utils/list'
import {
  isContourIntervalEnabled,
  isGeoTiffEnabled,
  isGeoTiffRGBEnabled,
} from '../jobOptionsUtils'
import { MapBackendNameToFrontend, MapUnitNameToAbbr, SPATIAL_FUSER_BACKEND_NAME } from '../constants'
import { getCurrentProject } from 'modules/projects/selectors'
import { CRSFields, lasFormats } from 'templates/CRS/constants'
import { getUnits } from 'modules/app/selectors'
import { getBackendString } from 'utils/coordinateSystem'
import WikiButton from 'components/WikiButton'
import { isOutputCRSJob, isPipelineSettingsJob, isPointcloudOptimizationJob } from 'utils/jobs'
import './style.css'
import { isLocalOrDevelopEnvironment } from 'config'
import { LasVisualizationSettings } from 'templates/CRS/LasCRSTemplate'

const replaceWhiteSpace = str => {
  return str.trim().replace(/\s/g, '_')
}

const getUnitsName = values => {
  const units = values && values.units
  return units ? MapUnitNameToAbbr[units.name] || units.name : 'm'
}

export const transformValueWithUnits = (value, values) => {
  const unitsName = getUnitsName(values)
  return unitsName === 'ft' || unitsName === 'us ft'
    ? value * 3.28084
    : value
}

const getFieldInitialValue = (values, mDefaultValue, ftDefaultValue) => {
  const unitsName = getUnitsName(values)
  if (unitsName === 'ft' || unitsName === 'us ft') {
    return ftDefaultValue
  }
  return mDefaultValue
}

export const getProjectArea = state => {
  const trajectoriesArtifacts = getSelectedTrajectoryArtifacts(state)
  const points = trajectoriesArtifacts.reduce((allPoints, trajectory) => [
    ...allPoints,
    ...(path(['properties', 'trajectorySample'], trajectory) || [])],
  []).map(point => turf.point([point.lng, point.lat]))
  const convexHull = turf.convex(turf.featureCollection(points), { concavity: Infinity })
  return convexHull ? turf.area(convexHull) / 2e7 : 0
}

const DTMGridStrideMin = [0.05, 0.15]
const DTMGridStrideDefault = [0.15, 0.5]
export const getGridStrideInitialValue = (state, values) => {
  let gridStride = getProjectArea(state)
  const minValue = getFieldInitialValue(values, ...DTMGridStrideDefault)
  gridStride = gridStride < minValue ? minValue : gridStride
  return gridStride.toFixed(2).toString()
}

const MeshCellSizeMin = [0.1, 0.3]
const MeshCellSizeDefault = [4, 12]

export const getMeshCellSizeDefault = (state, values) => {
  const gridStride = getProjectArea(state) * 10
  return transformValueWithUnits(gridStride, values)
}
export const getMeshCellSizeValue = (state, values) => {
  let gridStride = getMeshCellSizeDefault(state, values)
  const minValue = getFieldInitialValue(values, ...MeshCellSizeDefault)
  gridStride = gridStride < minValue ? minValue : gridStride
  return gridStride.toFixed(2).toString()
}

export const getUnitsInitialValue = state => {
  const selectedTemplate = getPipelineTemplate(state)
  const jobId = selectedTemplate.jobs.find(templateJob => isOutputCRSJob(templateJob.jobType)).templateId
  const jobValues = getJobOptions(state)[jobId]
  const units = getUnits(state)
  if (jobValues) {
    const unitsFromCRS = path(['rawValues', SPATIAL_FUSER_BACKEND_NAME, CRSFields.H_UNITS], jobValues)
    const stringRepresentationUnit = getBackendString(unitsFromCRS)
    const foundUnit = unitsFromCRS && units.find(unit => getBackendString(unit) === stringRepresentationUnit)
    if (foundUnit) {
      return foundUnit
    }
  }
  // Default units - metre
  return units.find(unit => getBackendString(unit) === 'EPSG:9001')
}

export const getExportCurvesInitialValue = state => {
  const selectedTemplate = getPipelineTemplate(state)
  const jobId = (selectedTemplate.jobs.find(templateJob =>
    isPipelineSettingsJob(templateJob.jobType) ||
    isPointcloudOptimizationJob(templateJob.jobType)
  ) || {}).templateId
  const jobValues = getJobOptions(state)[jobId]
  if (jobValues && path(['rawValues', 'dataset_type'], jobValues) === 'Mobile') {
    return true
  }
  return false
}

const getFieldLabel = fieldName => (state, values, extra) => {
  const unitsName = getUnitsName(values)
  return i18n.t(`templates.jobOptions.products.${fieldName}`, { metric: unitsName })
}

/**
 * Gets the initial visualization settings based on the provided state, extra parameters, and options.
 *
 * @param {Object} state - The current state of the application.
 * @param {Object} extra - Additional parameters that might be needed for initializing the settings.
 * @param {Object} options - Configuration options that include values and extra properties.
 * @returns {Object} The initial visualization settings, with each field initialized to its corresponding value.
 */
const getInitialVisualizationSettings = (state, extra, options) => {
  const visualizationSettings = options.values.visualization
  if (options.extraProps && options.extraProps.visualizationSettings) {
    options.extraProps.visualizationSettings = visualizationSettings
  }
  return Object.keys(LasVisualizationSettings).reduce((all, fieldName) => {
    const field = LasVisualizationSettings[fieldName]
    if (field.initialValue) {
      return { ...all, [fieldName]: field.initialValue(state, extra, options) }
    }
    return all
  }, {})
}

export default {
  units: {
    name: 'units',
    dataType: DataType.AUTOCOMPLETE,
    optional: true,
    invisible: true,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'units',
      () => getUnitsInitialValue(state)
    ),
    transformOnClone: (value, state, extra, options) => {
      return getUnitsInitialValue(state)
    },
  },
  base_name_text: {
    dataType: DataType.TEXT,
    initialValue: MapBackendNameToFrontend.base_name,
    sendToBackend: false,
    asLabel: true,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  base_name: {
    name: '', // MapBackendNameToFrontend.base_name,
    dataType: DataType.STRING,
    onChangeOn: 'blur',
    format: replaceWhiteSpace,
    inputProps: {
      maxlength: 80,
    },
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'base_name',
      (state, artifactId, options) => {
        const currentProject = getCurrentProject(state)
        return replaceWhiteSpace(currentProject.project.name)
      },
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  create_tiles: {
    name: MapBackendNameToFrontend.create_tiles,
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'create_tiles', false),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  tile_size: {
    name: getFieldLabel('tile_size'),
    dataType: DataType.FLOAT,
    precision: 2,
    disabled: (state, values) => !values.create_tiles,
    min: (state, values, value, options) => !values.create_tiles ? 0 : getFieldInitialValue(values, 150, 450),
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'tile_size',
      () => getFieldInitialValue(options.values, '300', '900'),
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  /*
  smart_decimation: {
    name: MapBackendNameToFrontend.smart_decimation,
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'smart_decimation', false),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 5,
      lg: 5,
    },
  },
  */
  cloud_clean_text: {
    dataType: DataType.TEXT,
    initialValue: 'CloudClean',
    sendToBackend: false,
    asLabel: true,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  cloud_clean: {
    name: '',
    dataType: DataType.SELECTION,
    options: ['Keep original cloud', 'Create smart-decimated cloud', 'Create smart-projected cloud'],
    mapping: {
      'Keep original cloud': 'original',
      'Create smart-decimated cloud': 'decimated',
      'Create smart-projected cloud': 'projected',
    },
    useMappingOnClone: true,
    // initialValue: (state, artifactId, options) => null,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'cloud_clean',
      'Keep original cloud'
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 4,
      lg: 4,
    },
  },
  smart_decimation_ppm: {
    name: MapBackendNameToFrontend.smart_decimation_ppm,
    dataType: DataType.INTEGER,
    min: 20,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'smart_decimation_ppm', '250'),
    disabled: (state, values) => values.cloud_clean !== 'Create smart-decimated cloud',
    gridProps: {
      xs: 12,
      sm: 12,
      md: 2,
      lg: 2,
    },
  },
  // Only for develop
  /*
  subsample_pointcloud: {
    name: MapBackendNameToFrontend.subsample_pointcloud,
    dataType: DataType.BOOLEAN,
    invisible: state => !isAdmin(state),
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'subsample_pointcloud', false),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 5,
      lg: 5,
    },
  },
  subsample_pointcloud_grid_stride: {
    name: getFieldLabel('subsample_pointcloud_grid_stride'),
    dataType: DataType.FLOAT,
    precision: 2,
    disabled: (state, values) => !values.subsample_pointcloud,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'subsample_pointcloud_grid_stride',
      () => getFieldInitialValue(options.values, '0.15', '0.5'),
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 7,
      lg: 7,
    },
  },
  */
  /*
  export_curves: {
    name: MapBackendNameToFrontend.export_curves,
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'export_curves',
      () => getExportCurvesInitialValue(state)
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
    createEmptyContainer: true,
    emptyContainerProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  warning: {
    dataType: DataType.TEXT,
    optional: true,
    variant: 'warning',
    invisible: (state, values) => {
      if (values.export_curves && values.subsample_pointcloud) {
        return false
      }
      return true
    },
    initialValue: i18n.t('templates.jobOptions.products.warning'),
    sendToBackend: false,
  },
  */
  use_laz: {
    name: (state, values, extra) => {
      return <span>{isLocalOrDevelopEnvironment() ? MapBackendNameToFrontend.use_laz : MapBackendNameToFrontend.use_laz_prod} {values.use_laz ? <WikiButton title={<ul className='tooltip-label-list'><Trans components={[<li key='item'>item</li>]} i18nKey='templates.jobOptions.products.use_laz_help' /></ul>} /> : null}</span>
    },
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'use_laz', true),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
    ...(
      !isLocalOrDevelopEnvironment() ? {
        createEmptyContainer: true,
        emptyContainerProps: {
          xs: 0,
          sm: 12,
          md: 6,
          lg: 6,
        }
      } : {}
    )
  },
  ...(isLocalOrDevelopEnvironment() ? {
    export_las: {
      name: MapBackendNameToFrontend.export_las,
      isLasField: true,
      dataType: DataType.MULTI_SELECTION,
      disabledOptions: ['export_las_1_4'],
      renderValue: (state, selected) => selected.map(item => lasFormats[item] || item).join(', '),
      options: () => Object.keys(lasFormats).map(key => ({ value: key, label: lasFormats[key] })),
      initialValue: (state, extra, options) => {
        const has_las_1_2 = getCloneJobOptionValue(state, extra, options, 'export_las_1_2', false)
        return has_las_1_2 ? ['export_las_1_4', 'export_las_1_2'] : ['export_las_1_4']
      },
    sendToBackend: false,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
    export_las_1_2: {
    name: MapBackendNameToFrontend.export_las_1_2,
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'export_las_1_2', false),
      invisible: true,
      optional: true,
      backendTransform: (original, state, values) => {
        return values.export_las.includes('export_las_1_2')
    },
  },
  }
    : {}),
  dsm_dem: {
    name: MapBackendNameToFrontend.dsm_dem,
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'dsm_dem', false),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  dsm_dem_pixel_size: {
    name: getFieldLabel('dsm_dem_pixel_size'),
    dataType: DataType.FLOAT,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'dsm_dem_pixel_size',
      () => getFieldInitialValue(options.values, '0.25', '0.75'),
    ),
    disabled: (state, values) => !isGeoTiffEnabled(state, values),
    getValue: (state, value, values) => !isGeoTiffEnabled(state, values)
      ? getFieldInitialValue(values, '0.25', '0.75')
      : value,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  geotiff_rgb: {
    name: MapBackendNameToFrontend.geotiff_rgb,
    dataType: DataType.BOOLEAN,
    invisible: (state, values) => {
      const cameraArtifacts = getSelectedCameraArtifacts(state)
      return cameraArtifacts.length <= 0
    },
    sendToBackend: (state, values) => {
      const cameraArtifacts = getSelectedCameraArtifacts(state)
      return cameraArtifacts.length > 0
    },
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'geotiff_rgb', false),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  geotiff_rgb_pixel_size: {
    name: getFieldLabel('geotiff_rgb_pixel_size'),
    dataType: DataType.FLOAT,
    invisible: (state, values) => {
      const cameraArtifacts = getSelectedCameraArtifacts(state)
      return cameraArtifacts.length <= 0
    },
    sendToBackend: (state, values) => {
      const cameraArtifacts = getSelectedCameraArtifacts(state)
      return cameraArtifacts.length > 0
    },
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'geotiff_rgb_pixel_size',
      () => getFieldInitialValue(options.values, '0.25', '0.75'),
    ),
    disabled: (state, values) => !isGeoTiffRGBEnabled(state, values),
    getValue: (state, value, values) => !isGeoTiffRGBEnabled(state, values)
      ? getFieldInitialValue(values, '0.25', '0.75')
      : value,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  contours: {
    name: MapBackendNameToFrontend.contours,
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'contours', false),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  contour_interval: {
    name: getFieldLabel('contour_interval'),
    dataType: DataType.FLOAT,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'contour_interval',
      () => getFieldInitialValue(options.values, '0.5', '1.5'),
    ),
    disabled: (state, values) => !isContourIntervalEnabled(state, values),
    getValue: (state, value, values) => !isContourIntervalEnabled(state, values)
      ? getFieldInitialValue(values, '0.5', '1.5')
      : value,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  dtm_grid_stride_initial: {
    dataType: DataType.FLOAT,
    precision: 2,
    invisible: true,
    sendToBackend: false,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'dtm_grid_stride',
      () => getGridStrideInitialValue(state, options.values)
    ),
  },
  create_dtm_grid: {
    name: MapBackendNameToFrontend.create_dtm_grid,
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'create_dtm_grid', true),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  dtm_grid_stride: {
    name: getFieldLabel('dtm_grid_stride'),
    dataType: DataType.FLOAT,
    precision: 2,
    min: (state, values, value, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      const minValue = getFieldInitialValue(values, ...DTMGridStrideMin)
      const defaultValue = getFieldInitialValue(values, ...DTMGridStrideDefault)
      return clone
        ? DTMGridStrideMin[0]
        : values.dtm_grid_stride_initial === defaultValue.toString()
          ? minValue
          : +values.dtm_grid_stride_initial
    },
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'dtm_grid_stride',
      () => getGridStrideInitialValue(state, options.values),
    ),
    disabled: (state, values) => !values.create_dtm_grid,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  create_pointcloud_viewer: {
    name: (state, values, extra) => {
      return <span>{MapBackendNameToFrontend.create_pointcloud_viewer} <WikiButton title={<Trans i18nKey='templates.jobOptions.products.create_pointcloud_viewer_help'/>}/></span>
    },
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'create_pointcloud_viewer', true),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  visualization: {
    dataType: DataType.CONFIGURE_POINTCLOUD_VIEWER,
    optional: true,
    disabled: (state, values) => !values.create_pointcloud_viewer,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'visualization', getInitialVisualizationSettings(state, extra, options)),
    sendToBackend: (state, values) => values.send_visualization,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  // Used to check whether the `visualization` option should be sent to the server.
  // Returns true if the user changes some visualization properties.
  send_visualization: {
    dataType: DataType.BOOLEAN,
    optional: true,
    invisible: true,
    initialValue: false,
    sendToBackend: false,
    transformOnChangeOf: ['visualization'],
    transform: (
      oldValue,
      state,
      valuesBeforeTransform,
      extra,
      formTemplate,
      shouldBeTransformed,
      oldValues,
    ) => {
      const initialValue = getInitialVisualizationSettings(state, extra, { formTemplate, values: valuesBeforeTransform })
      if (!equals(initialValue, valuesBeforeTransform.visualization)) return true
      return false

    },
  },
  tin_mesh: {
    name: (state, values, extra) => {
      return <span>{MapBackendNameToFrontend.tin_mesh} <WikiButton title={MapBackendNameToFrontend.tin_mesh_tooltip}/></span>
    },
    dataType: DataType.BOOLEAN,
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'tin_mesh', false),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  mesh_cell_size_initial: {
    dataType: DataType.FLOAT,
    precision: 2,
    invisible: true,
    sendToBackend: false,
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'mesh_cell_size',
      () => getMeshCellSizeValue(state, options.values),
    ),
  },
  mesh_cell_size: {
    name: getFieldLabel('mesh_cell_size'),
    dataType: DataType.FLOAT,
    precision: 2,
    min: (state, values, value, options) => {
      const minValue = getFieldInitialValue(values, ...MeshCellSizeMin)
      const defaultValue = getMeshCellSizeDefault(state, values)
      return defaultValue > minValue
        ? defaultValue.toFixed(2)
        : minValue
    },
    initialValue: (state, extra, options) => getCloneJobOptionValue(
      state,
      extra,
      options,
      'mesh_cell_size',
      () => getMeshCellSizeValue(state, options.values),
    ),
    disabled: (state, values) => !values.tin_mesh,
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  /*
  dtm_max_edge_length: {
    name: getFieldLabel('dtm_max_edge_length'),
    dataType: DataType.FLOAT,
    precision: 2,
    initialValue: (state, extra, options) =>
      getCloneJobOptionValue(state, extra, options, 'dtm_max_edge_length', '3'),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 6,
    },
  },
  create_orthomosaic: {
    name: MapBackendNameToFrontend.create_orthomosaic,
    dataType: DataType.BOOLEAN,
    disabled: (state, values) => {
      const cameraArtifacts = [...getSelectedCameraArtifacts(state), ...getSelectedReconArtifacts(state)]
      if (cameraArtifacts.length <= 0) {
        return true
      }
      return false
    },
    getValue: (state, value, values, extra) => {
      const cameraArtifacts = [...getSelectedCameraArtifacts(state), ...getSelectedReconArtifacts(state)]
      if (cameraArtifacts.length <= 0) {
        return false
      }
      return value
    },
    backendTransform: (original, state, values) => {
      const cameraArtifacts = [...getSelectedCameraArtifacts(state), ...getSelectedReconArtifacts(state)]
      if (cameraArtifacts.length <= 0) {
        return false
      }
      return original
    },
    invisible: state => !isAdmin(state),
    initialValue: (state, extra, options) => getCloneJobOptionValue(state, extra, options, 'create_orthomosaic', false),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 5,
      lg: 5,
    },
    createEmptyContainer: true,
    emptyContainerProps: {
      xs: 12,
      sm: 12,
      md: 7,
      lg: 7,
    },
  },
  */
}
